export function checkForm1(result) {
	let isValid = true;
	if (
		result.label == undefined ||
		result.answers == undefined ||
		!result.answers instanceof Array ||
		result.contrasts == undefined
	) {
		isValid = false;
	} else if (result.answers) {
		if (result.answers.length !== 5) {
			isValid = false;
		} else {
			result.answers.forEach((item) => {
				if (item.select == undefined || item.note == undefined) {
					isValid = false;
				}
			});
		}
	}

	return isValid;
}
