<template>
	<div ref="fillBuilder$" class="px-3">
		<h3 class="text-primary mb-3">{{ formTitle }}</h3>

		<div v-show="!loading">
			<!-- 自我五項評分表 -->
			<div v-if="formID == 1" class="form-type-1">
				<p class="white-space-pre-wrap">{{ formSchema_1.title }}</p>

				<div class="form-table d-flex my-5">
					<div class="border border-bottom-0 border-end-0">
						<div
							class="block p-3 border-bottom"
							v-for="(item, index) in formSchema_1.questions"
							:key="item"
						>
							<div class="w-100">
								<p>{{ item }}</p>
								<textarea
									maxlength="200"
									rows="2"
									class="form-control"
									v-model="form_1.data.answers[index].note"
									@change="formOnChange()"
									style="resize: none"
									:disabled="formDisabled"
								></textarea>
							</div>
						</div>
					</div>

					<draggable
						:list="form_1.data.contrasts"
						item-key="id"
						class="d-flex"
						ghost-class="ghost"
						@start="dragging = true"
						@end="dragging = false"
						handle=".handle"
					>
						<template #item="{ element, index }">
							<div class="border border-bottom-0 border-end-0 position-relative">
								<div class="label-title">
									{{ element.label }}
								</div>
								<div class="block p-3 border-bottom" v-for="item in element.records" :key="item.id">
									<select class="form-control form-control-sm me-2" disabled :value="item">
										<option v-for="i in 10" :value="i" :key="i">{{ i }}</option>
									</select>
									<span>分</span>
								</div>
								<div v-if="!formDisabled" class="tools">
									<a class="close" @click="form_1.removeContrast(index)" role="button">
										<font-awesome-icon :icon="['fas', 'trash-can']"
									/></a>
									<a class="handle" role="button"><font-awesome-icon :icon="['fas', 'bars']" /></a>
								</div>
							</div>
						</template>
					</draggable>

					<div class="border border-bottom-0 position-relative">
						<div class="label-title">
							<input
								v-model="form_1.data.label"
								type="text"
								class="form-control form-control-sm"
								placeholder="自評表標籤"
								@change="formOnChange()"
								:disabled="formDisabled"
							/>
						</div>
						<div class="block p-3 border-bottom" v-for="(item, index) in form_1.data.answers" :key="index">
							<select
								class="form-control form-control-sm me-2"
								v-model="item.select"
								@change="formOnChange()"
								:disabled="formDisabled"
							>
								<option v-for="i in 10" :value="i" :key="i">{{ i }}</option>
							</select>
							<span>分</span>
						</div>
						<div v-if="!formDisabled" class="tools">
							<button class="btn btn-sm btn-primary" @click="addContrast.getList()">插入比較</button>
						</div>
					</div>
				</div>
			</div>

			<!-- 承諾表 -->
			<div v-else-if="formID == 2">
				<Vueform
					ref="form_2$"
					:schema="formSchema_2"
					:display-errors="false"
					validate-on="change"
					:disabled="formDisabled"
					:add-classes="{
						FormElements: {
							container: 'd-grid gap-4',
						},
						TextElement: {
							inputContainer_disabled: 'bg-light border-0 shadow-none',
						},
						TextareaElement: {
							inputContainer_disabled: 'bg-light border-0 shadow-none',
						},
						DateElement: {
							inputContainer_disabled: 'bg-light border-0 shadow-none',
						},
						SelectElement: {
							select: {
								container_disabled: 'bg-light border-0 shadow-none',
							},
						},
					}"
				>
				</Vueform>
			</div>

			<!-- 追蹤表 -->
			<div v-else-if="formID == 3">
				<Vueform
					ref="form_3$"
					:schema="formSchema_3"
					:display-errors="false"
					validate-on="change"
					:disabled="formDisabled"
					:add-classes="{
						FormElements: {
							container: 'd-grid gap-4',
						},
						TextElement: {
							inputContainer_disabled: 'bg-light border-0 shadow-none',
						},
						TextareaElement: {
							inputContainer_disabled: 'bg-light border-0 shadow-none',
						},
						DateElement: {
							inputContainer_disabled: 'bg-light border-0 shadow-none',
						},
						SelectElement: {
							select: {
								container_disabled: 'bg-light border-0 shadow-none',
							},
						},
					}"
				>
				</Vueform>

				<button v-if="!formDisabled" class="btn btn-primary mt-4" @click="addPromise.getList()">
					匯入承諾
				</button>
			</div>

			<!-- 一般表單 -->
			<div v-else>
				<Vueform
					ref="regularForm$"
					:schema="regularSchema"
					:display-errors="false"
					validate-on="change"
					:disabled="formDisabled"
					:add-classes="{
						FormElements: {
							container: 'd-grid gap-4',
						},
						TextElement: {
							inputContainer_disabled: 'bg-light border-0 shadow-none',
						},
						TextareaElement: {
							inputContainer_disabled: 'bg-light border-0 shadow-none',
						},
						DateElement: {
							inputContainer_disabled: 'bg-light border-0 shadow-none',
						},
						SelectElement: {
							select: {
								container_disabled: 'bg-light border-0 shadow-none',
							},
						},
					}"
				>
				</Vueform>
			</div>
		</div>

		<div v-if="loading" class="text-center p-5">
			<div class="spinner-border text-secondary" role="status"></div>
		</div>
	</div>

	<!-- 匯入承諾 Modal -->
	<div ref="addPromiseModal$" class="modal fade" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">請選擇您要匯入的承諾</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal"></button>
				</div>
				<div class="modal-body">
					<table class="table table-hover mb-5">
						<thead class="bg-light">
							<tr>
								<th>上課日期</th>
								<th>課程名稱</th>
								<th class="text-center">課程堂次</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in addPromise.list" :key="item.id" @click="addPromise.setPromise(item)">
								<td>{{ formatDatetime(item.class_date) }}</td>
								<td>{{ item.lesson_name }}</td>
								<td class="text-center">{{ item.lesson_count }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<!-- 匯入比較 Modal -->
	<div ref="addContrastModal$" class="modal fade" tabindex="-1">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">請選擇您要匯入的比較</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal"></button>
				</div>
				<div class="modal-body">
					<table class="table table-hover mb-5">
						<thead class="bg-light">
							<tr>
								<th>上課日期</th>
								<th>課程名稱</th>
								<th class="text-center">自評表標籤</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in addContrast.list" :key="item.id" @click="addContrast.setContrast(item)">
								<td>{{ formatDatetime(item.class_date) }}</td>
								<td>{{ item.lesson_name }}</td>
								<td class="text-center">{{ item.label }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<!-- 索引 Toast -->
	<div class="index-toast toast-container position-fixed top-0 end-0 p-3">
		<div ref="indexToast$" class="toast" role="alert" data-bs-delay="60000">
			<div class="toast-header">
				<strong class="me-auto">{{ indexData.data.title }}</strong>
			</div>
			<div class="toast-body">{{ indexData.data.content }}</div>
		</div>
	</div>
</template>

<script>
import Swal from "sweetalert2";
import { formatLabel, invalidMessage, formatDatetime } from "@/assets/js/common.js";
import { checkForm1 } from "@/assets/js/checkCustomForm";
import { ref, reactive, computed, nextTick, onMounted } from "vue";
import { apiIndexGetByCode, apiGetSpecialTemplate, apiClassTemplate } from "@/assets/js/api.js";
import { Toast, Modal } from "bootstrap";
import { useStore } from "vuex";
import draggable from "vuedraggable";

export default {
	name: "FillBuilder",
	emits: ["formOnChange"],
	props: {
		id: {
			default: 0,
		},
		clientID: {
			default: 0,
		},
		formDisabled: {
			default: false,
		},
	},
	components: {
		draggable,
	},
	setup(props, { emit }) {
		const store = useStore();

		const fillBuilder$ = ref(null);

		const dragging = ref(false);
		const loading = ref(false);

		const addPromiseModal$ = ref(null);
		const addContrastModal$ = ref(null);
		const indexToast$ = ref(null);

		const formID = ref(0);
		const formTitle = ref("");

		const customID = computed(() => store.state.template.customID);

		let isInit = false;

		// 一般表單
		const regularForm$ = ref(null);
		const regularSchema = ref({});

		// 自我五項評分表
		const form_1 = reactive({
			data: {
				label: "",
				answers: [
					{
						select: 0,
						note: "",
					},
					{
						select: 0,
						note: "",
					},
					{
						select: 0,
						note: "",
					},
					{
						select: 0,
						note: "",
					},
					{
						select: 0,
						note: "",
					},
				],
				contrasts: [
					/*
						物件格式
						{
							id,
							label: "首次課前",
							records: [2, 3, 4, 5, 6],
						},
					*/
				],
			},
			removeContrast(index) {
				Swal.fire({
					icon: "question",
					text: "確定要刪除嗎?",
					showCancelButton: true,
					confirmButtonText: "確定",
					cancelButtonText: "取消",
				}).then((result) => {
					if (result.isConfirmed) {
						form_1.data.contrasts.splice(index, 1);
						formOnChange();
					}
				});
			},
		});
		const formSchema_1 = ref({});

		// 承諾表
		const form_2$ = ref(null);
		const formSchema_2 = ref({});

		// 追蹤表
		const form_3$ = ref(null);
		const formSchema_3 = ref({});

		//健康資訊報告
		const form_4_data = ref({});

		// 索引
		const indexData = reactive({
			toast: {},
			data: {
				status: "",
				title: "",
				content: "",
			},
			tempData: {},
			getDetail(code) {
				if (indexData.tempData[code]) {
					if (indexData.tempData[code].status == 1) {
						indexData.data = indexData.tempData[code];
						indexData.toast.show();
					} else {
						indexData.toast.hide();
					}
				} else {
					apiIndexGetByCode({
						code,
					})
						.then((response) => {
							if (response.data.status == "0" && response.data.data.index.status == 1) {
								const dataObj = {
									status: response.data.data.index.status,
									title: response.data.data.index.title,
									content: response.data.data.index.content,
								};
								indexData.data = dataObj;

								//將結果存入物件重複使用，避免重複查詢
								indexData.tempData[code] = dataObj;

								indexData.toast.show();
							} else {
								indexData.tempData[code] = {
									status: 0,
								};

								getDetail(code);
							}
						})
						.catch((error) => {
							console.error(error);
							Swal.fire({
								icon: "error",
								text: "CODE ERROR",
							});
						});
				}
			},
			addIndexEvent() {
				let dataIndex = fillBuilder$.value.querySelectorAll("[data-index]");

				dataIndex.forEach((item) => {
					item.addEventListener("mouseenter", () => {
						let indexCode = item.getAttribute("data-index");

						indexData.getDetail(indexCode);
					});

					item.addEventListener("mouseleave", () => {
						indexData.toast.hide();
					});
				});
			},
		});

		// 追蹤表 匯入承諾
		const addPromise = reactive({
			modal: {},
			list: [],
			getList() {
				if (props.clientID == 0) {
					return;
				}
				addPromise.list = [];

				apiGetSpecialTemplate({
					client_id: props.clientID,
					template_type: 2,
				}).then((response) => {
					if (response.data.status == "0") {
						addPromise.list = response.data.data;
					} else {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						});
					}

					addPromise.modal.show();
				});
			},
			setPromise(item) {
				let { content } = item;
				content = content.length > 0 ? JSON.parse(content) : {};

				let now = formatDatetime(new Date());
				form_3$.value.clear();

				let newData = {
					action_1: {
						question: content[2],
					},
					action_2: {
						question: content[3],
					},
					action_3: {
						question: content[4],
					},
					action_4: {
						question: content[5],
					},
					action_5: {
						question: content[6],
					},
					date: now,
				};

				form_3$.value.load(newData);

				setTimeout(() => {
					formOnChange();
					addPromise.modal.hide();
				}, 500);
			},
		});

		// 自我五項評分表 插入比較
		const addContrast = reactive({
			modal: {},
			list: [],
			getList() {
				if (props.clientID == 0) {
					return;
				}

				if (form_1.data.contrasts.length == 2) {
					Swal.fire({
						icon: "info",
						text: "最多插入2筆比較",
					});

					return;
				}

				addContrast.list = [];

				apiGetSpecialTemplate({
					client_id: props.clientID,
					template_type: 1,
				}).then((response) => {
					if (response.data.status == "0") {
						let list = response.data.data.filter((item) => item.id !== props.id);

						addContrast.list = list.map((item) => {
							let { id, class_date, lesson_name, content } = item;
							let label = "";
							let records = [];

							if (content.length > 0) {
								content = JSON.parse(content);
								label = content.label;
								records = content.answers.map((item) => item.select);
							}

							return {
								id,
								class_date,
								lesson_name,
								label,
								records,
							};
						});
					} else {
						Swal.fire({
							icon: "warning",
							text: response.data.message,
						});
					}

					addContrast.modal.show();
				});
			},
			setContrast(item) {
				form_1.data.contrasts.push(item);

				formOnChange();
				addContrast.modal.hide();
			},
		});

		const setFormData = ({ templateID = 0, title, questions }) => {
			/*
				templateID: 表單id
					0 一般表單
					1 自我五項評分表
					2 承諾表
					3 追蹤表
					4 健康資訊報告
			*/
			return new Promise((resolve, reject) => {
				formID.value = templateID;

				loading.value = true;

				setTimeout(() => {
					formTitle.value = title;

					if (templateID == 1) {
						// 自我五項評分表
						let schema = {
							title: questions[0].title,
							questions: [],
						};

						for (let i = 1; i < questions.length; i++) {
							schema.questions.push(questions[i].title);
						}

						formSchema_1.value = schema;
					} else if (templateID == 2) {
						// 承諾表
						let schema = {};

						for (let i = 0; i < questions.length; i++) {
							let { type, title } = questions[i];
							title = formatLabel(title);

							let index = i;
							let objectSchema = {};

							if (type == "content") {
								objectSchema = {
									content: title,
									type: "static",
								};
							} else if (type == "text_inline") {
								objectSchema = {
									label: title,
									type: "text",
									default: "",
									rules: ["min:1", "max:60"],
									messages: {
										min: invalidMessage("min:1"),
										max: invalidMessage("max:60"),
									},
									addClasses: {
										ElementLayout: {
											outerWrapper: "d-flex flex-wrap align-items-center",
										},
									},
									disabled: props.formDisabled,
									onBlur: formOnChange,
								};
							}

							schema[index] = objectSchema;
						}

						formSchema_2.value = schema;
					} else if (templateID == 3) {
						// 追蹤表
						let schema = {
							title: {
								content: questions[0].title,
								type: "static",
							},
							action_1: {
								type: "object",
								schema: {
									question: {
										label: "行動1:",
										type: "text",
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										columns: { container: 12, label: 2, wrapper: 12 },
										disabled: props.formDisabled,
									},
									answer: {
										type: "textarea",
										autogrow: false,
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										disabled: props.formDisabled,
									},
								},
							},
							action_2: {
								type: "object",
								schema: {
									question: {
										label: "行動2:",
										type: "text",
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										columns: { container: 12, label: 2, wrapper: 12 },
										disabled: props.formDisabled,
									},
									answer: {
										type: "textarea",
										autogrow: false,
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										disabled: props.formDisabled,
									},
								},
							},
							action_3: {
								type: "object",
								schema: {
									question: {
										label: "行動3:",
										type: "text",
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										columns: { container: 12, label: 2, wrapper: 12 },
										disabled: props.formDisabled,
									},
									answer: {
										type: "textarea",
										autogrow: false,
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										disabled: props.formDisabled,
									},
								},
							},
							action_4: {
								type: "object",
								schema: {
									question: {
										label: "行動4:",
										type: "text",
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										columns: { container: 12, label: 2, wrapper: 12 },
										disabled: props.formDisabled,
									},
									answer: {
										type: "textarea",
										autogrow: false,
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										disabled: props.formDisabled,
									},
								},
							},
							action_5: {
								type: "object",
								schema: {
									question: {
										label: "行動5:",
										type: "text",
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										columns: { container: 12, label: 2, wrapper: 12 },
										disabled: props.formDisabled,
									},
									answer: {
										type: "textarea",
										autogrow: false,
										default: "",
										rules: ["min:1", "max:60"],
										messages: {
											min: invalidMessage("min:1"),
											max: invalidMessage("max:60"),
										},
										onBlur: formOnChange,
										disabled: props.formDisabled,
									},
								},
							},
							date: {
								label: "匯入日期",
								type: "date",
								default: "",
								displayFormat: "YYYY-MM-DD",
								loadFormat: "YYYY-MM-DD",
								valueFormat: "YYYY-MM-DD",
								disabled: true,
								conditions: [
									function (form$) {
										let date$ = form$.el$("date");
										if (date$.value) {
											return date$.value.length > 0;
										} else {
											return false;
										}
									},
								],
							},
						};

						formSchema_3.value = schema;
					} else {
						// 一般表單 or 健康資訊報告
						let schema = {};
						for (let i = 0; i < questions.length; i++) {
							let { type, title, selectOptions, checkOptions } = questions[i];
							title = formatLabel(title);

							let index = i;
							let objectSchema = {};

							if (type == "title") {
								objectSchema = {
									content: title,
									type: "static",
									size: "lg",
									disabled: props.formDisabled,
								};
							} else if (type == "content") {
								objectSchema = {
									content: title,
									type: "static",
									disabled: props.formDisabled,
								};
							} else if (type == "text_inline") {
								objectSchema = {
									label: title,
									type: "text",
									default: "",
									rules: ["min:1", "max:60"],
									messages: {
										min: invalidMessage("min:1"),
										max: invalidMessage("max:60"),
									},
									addClasses: {
										ElementLayout: {
											outerWrapper: "d-flex flex-wrap align-items-center",
										},
									},
									onBlur: formOnChange,
									disabled: props.formDisabled,
								};
							} else if (type == "textarea") {
								objectSchema = {
									label: title,
									type: "textarea",
									autogrow: false,
									default: "",
									rules: ["min:1", "max:60"],
									messages: {
										min: invalidMessage("min:1"),
										max: invalidMessage("max:60"),
									},
									onBlur: formOnChange,
									disabled: props.formDisabled,
								};
							} else if (type == "textarea_lg") {
								objectSchema = {
									label: title,
									type: "textarea",
									autogrow: false,
									default: "",
									rules: ["min:1", "max:1200"],
									messages: {
										min: invalidMessage("min:1"),
										max: invalidMessage("max:1200"),
									},
									onBlur: formOnChange,
									readonly: props.formDisabled,
								};

								if (props.formDisabled) {
									objectSchema.addClasses = {
										TextareaElement: {
											inputContainer: "textarea-readonly",
										},
									};
								}
							} else if (type == "radiogroup" || type == "checkboxgroup") {
								let checkType = type == "radiogroup" ? "radio" : "checkbox";
								let onChangeEvent = type == "radiogroup" ? singleCheck : formOnChange;

								let items = [];

								let labelSelectItems = [];

								for (let i = 0; i < checkOptions.length; i++) {
									const iTitle = formatLabel(checkOptions[i].label);
									const iAllowText = checkOptions[i].allowText;
									items.push({
										index: i,
										label: iTitle,
										check: false,
										allowText: iAllowText,
										text: "",
									});

									labelSelectItems.push(iTitle);
								}

								objectSchema = {
									label: title,
									type: "list",
									disabled: true,
									controls: {
										add: false,
									},
									object: {
										overrideClasses: {
											ObjectElement: {
												wrapper: "d-flex align-items-center",
											},
										},
										schema: {
											index: {
												type: "text",
												overrideClasses: {
													TextElement: {
														container: "d-none",
													},
												},
											},
											check: {
												type: checkType,
												default: 0,
												radioValue: true,
												onChange: onChangeEvent,
												disabled: props.formDisabled,
											},
											label: {
												type: "select",
												disabled: true,
												native: false,
												items: labelSelectItems,
												overrideClasses: {
													SelectElement: {
														select: {
															singleLabel: "me-3",
															container: "border-0 p-0 bg-transparent text-dark",
															caret: "d-none",
														},
													},
												},
											},
											allowText: {
												label: "開放填答",
												type: "checkbox",
												default: 0,
												radioValue: true,
												overrideClasses: {
													CheckboxElement: {
														container: "d-none",
													},
												},
											},
											text: {
												label: "自填選項",
												type: "text",
												default: "",
												rules: ["min:1", "max:60"],
												messages: {
													min: invalidMessage("min:1"),
													max: invalidMessage("max:60"),
												},
												conditions: [[`${index}.*.allowText`, "==", true]],
												overrideClasses: {
													ElementLayout: {
														container: "flex-grow-1",
													},
													ElementLabel: {
														container: "d-none",
													},
												},
												onBlur: formOnChange,
												disabled: props.formDisabled,
											},
										},
									},
									default: items,
								};
							} else if (type == "select") {
								let items = [];
								for (let key in selectOptions) {
									items.push({
										value: key,
										label: formatLabel(selectOptions[key]),
									});
								}

								objectSchema = {
									label: title,
									type: "select",
									default: "",
									items,
									native: false,
									search: true,
									onSelect: formOnChange,
									disabled: props.formDisabled,
								};
							} else if (type == "checkbox") {
								objectSchema = {
									text: title,
									type: "checkbox",
									onChange: formOnChange,
									disabled: props.formDisabled,
								};
							} else if (type == "date") {
								let now = new Date();

								objectSchema = {
									text: title,
									type: "date",
									default: now,
									displayFormat: "YYYY-MM-DD",
									loadFormat: "YYYY-MM-DD",
									valueFormat: "YYYY-MM-DD",
									addClasses: {
										ElementAddon: {
											wrapper: "date-element-addon",
										},
									},
									addons: {
										before: " ",
									},
									onChange: formOnChange,
									disabled: props.formDisabled,
								};
							} else if (type == "time") {
								let now = new Date();

								objectSchema = {
									text: title,
									type: "date",
									date: false,
									time: true,
									seconds: true,
									default: now,
									displayFormat: "hh:mm:ss",
									valueFormat: "hh:mm:ss",
									addClasses: {
										ElementAddon: {
											wrapper: "time-element-addon",
										},
									},
									addons: {
										before: " ",
									},
									onChange: formOnChange,
									disabled: props.formDisabled,
								};
							}

							schema[index] = objectSchema;
						}

						regularSchema.value = schema;

						if (templateID == 4) {
							form_4_data.value = questions;
						}
					}

					nextTick(() => {
						indexData.addIndexEvent();
						loading.value = false;

						resolve();
					});
				}, 100);
			});
		};

		const setFillData = (data) => {
			isInit = true;

			if (formID.value == 1) {
				// 自我五項評分表
				if (Object.keys(data).length > 0) {
					//檢查格式
					const isValid = checkForm1(data);
					if (!isValid) {
						Swal.fire({
							icon: "warning",
							text: "「自我五項評分表」資料格式錯誤，請重新建立表單",
						});

						isInit = false;
						return;
					}

					form_1.data.label = data.label;
					form_1.data.answers = data.answers;

					if (data.contrasts.length > 0) {
						Promise.all(
							data.contrasts.map((id) => {
								return new Promise((resolve, reject) => {
									return apiClassTemplate({
										method: "get",
										id,
									}).then((response) => {
										if (response.data.status == "0") {
											resolve(response.data.data.template);
										} else {
											// 資料錯誤
											resolve(false);
										}
									});
								});
							})
						)
							.then((result) => {
								// 去除資料錯誤的項目
								result = result.filter((item) => {
									return item;
								});

								form_1.data.contrasts = result.map((item) => {
									let label = "";
									let records = [];
									let content = item.content;

									if (content.length > 0) {
										content = JSON.parse(content);

										label = content.label;
										records = content.answers.map((item) => item.select);
									}

									return {
										id: item.id,
										label,
										records,
									};
								});
							})
							.catch((err) => {
								console.error(err);
							});
					}
				}
			} else if (formID.value == 2) {
				// 承諾表
				if (Object.keys(data).length > 0) {
					form_2$.value.load(data);
				}
			} else if (formID.value == 3) {
				// 追蹤表
				form_3$.value.load(data);
			} else if (formID.value == 4) {
				// 健康資訊報告
				if (Object.keys(data).length > 0) {
					let newData = {};

					for (let key in data) {
						newData[key] = data[key].data;
					}
					regularForm$.value.load(newData);
				}
			} else {
				// 一般表單
				if (Object.keys(data).length > 0) {
					regularForm$.value.load(data);
				}
			}

			setTimeout(() => {
				isInit = false;
			}, 1000);
		};

		const getResult = () => {
			return new Promise((resolve, reject) => {
				if (formID.value == 1) {
					// 自我五項評分表
					let data = {
						label: "",
						answers: [],
						contrasts: [],
					};
					let { answers, contrasts, label } = form_1.data;

					data.label = label;
					data.answers = answers;
					data.contrasts = contrasts.map((item) => item.id);

					resolve(data);
				} else if (formID.value == 2) {
					// 承諾表
					form_2$.value.validate().then(() => {
						if (!form_2$.value.invalid) {
							const data = form_2$.value.data;
							resolve(data);
						} else {
							reject();
						}
					});
				} else if (formID.value == 3) {
					// 追蹤表
					const data = form_3$.value.data;
					resolve(data);
				} else if (formID.value == 4) {
					// 健康資訊報告
					regularForm$.value.validate().then(() => {
						if (!regularForm$.value.invalid) {
							const data = regularForm$.value.data;

							let newData = {};
							for (let i in data) {
								newData[i] = {
									data: data[i],
									key: "",
								};

								let questionKey = form_4_data.value[i].key;
								if (questionKey) {
									newData[i].key = questionKey;
								}
							}

							resolve(newData);
						} else {
							reject();
						}
					});
				} else {
					// 一般表單
					regularForm$.value.validate().then(() => {
						if (!regularForm$.value.invalid) {
							const data = regularForm$.value.data;
							resolve(data);
						} else {
							reject();
						}
					});
				}
			});
		};

		const singleCheck = (newValue, oldValue, el$) => {
			const listEl = el$.parent.parent.el$;
			const index = el$.parent.value.index;

			if (newValue == 1) {
				const formListValue = listEl.value;

				let newListValue = formListValue.map((item, itemIndex) => {
					return {
						index: itemIndex,
						label: item.label,
						check: index === itemIndex,
						allowText: item.allowText,
						text: item.text,
					};
				});

				listEl.update(newListValue);
			}

			formOnChange();
		};

		const formOnChange = () => {
			if (isInit || props.clientID == 0) {
				return;
			}
			getResult()
				.then((result) => {
					emit("formOnChange", result);
				})
				.catch((error) => {
					console.error(error);
					Swal.fire({
						icon: "error",
						text: "CODE ERROR",
					});
				});
		};

		onMounted(() => {
			indexData.toast = new Toast(indexToast$.value);
			addPromise.modal = new Modal(addPromiseModal$.value);
			addContrast.modal = new Modal(addContrastModal$.value);
		});

		return {
			fillBuilder$,
			loading,
			formDisabled: props.formDisabled,
			dragging,
			indexToast$,
			addPromiseModal$,
			addContrastModal$,
			formID,
			formTitle,
			regularForm$,
			regularSchema,
			form_1,
			formSchema_1,
			form_2$,
			formSchema_2,
			form_3$,
			formSchema_3,

			indexData,
			customID,
			addPromise,
			addContrast,
			setFormData,
			setFillData,
			getResult,
			formOnChange,
			formatDatetime,
		};
	},
};
</script>

<style lang="scss" scoped>
:deep([data-index]) {
	text-decoration: underline;
	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
}

:deep(.textarea-readonly) {
	outline: 0 !important;
	border: 0 !important;
	box-shadow: none !important;

	textarea {
		background-color: $light !important;
	}
}

.form-control:disabled,
.form-control[readonly] {
	background-color: $light;
	border: 0;
}

.index-toast {
	z-index: 2000;

	.toast {
		width: 500px;
		background-color: transparent;
	}

	.toast-header,
	.toast-body {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(5px);
		font-size: 1rem;
	}

	.toast-body {
		white-space: pre-wrap;
		max-height: 400px;
		overflow: auto;
		padding: 1rem 2rem;
	}
}

.form-type-1 {
	overflow: auto;
	.form-table {
		min-width: 530px;
	}

	.block {
		display: flex;
		align-items: center;
		height: 160px;
	}

	select {
		width: 50px;
		text-align: center;
	}

	.ghost {
		opacity: 0.3;
	}

	.label-title {
		position: absolute;
		top: -45px;
		left: calc(50% - 50px);
		width: 100px;
		height: 40px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}

	.tools {
		position: absolute;
		bottom: -45px;
		left: calc(50% - 50px);
		width: 100px;
		height: 40px;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
	}
}
</style>
